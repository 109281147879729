import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const NotFoundPage = ({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
  },
}) => (
  <Layout>
    <Helmet title={`${siteTitle} - Contact`} />
    <div id="pageheader">
      <h1>Not Found</h1>
    </div>
    <p>You just hit a route that doesn't exist... the sadness.</p>
  </Layout>
)

export const pageQuery = graphql`
  query four_oh_four {
    site: site {
      siteMetadata {
        title
      }
    }
  }
`

export default NotFoundPage
